<template>

  <div class="row" ref="electoralDistrictForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The Name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Name *"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="name"
              rules=""
              name="The english name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="english Name"
                        name="en_name"
                        fou
                        v-model="formData.en_name">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="name"
              rules=""
              name="The french name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="french Name"
                        name="fr_name"
                        fou
                        v-model="formData.fr_name">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="name"
              rules=""
              name="The spanish name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="spanish Name"
                        name="sp_name"
                        fou
                        v-model="formData.es_name">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ passed, failed,errors }"
              name="The Electoral Districts"
              rules="required"
              vid="electoral_department_id">
              <fg-select
                v-model="formData.electoral_department_id"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Electoral Districts *'"
                :list="departmentList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                clearable
                filterable
                name="electoral_department_id"
                placeholder="Electoral Districts"
                size="large">
              </fg-select>
            </ValidationProvider>
            <ValidationProvider
              vid="electors"
              rules="required"
              name="The Electors"
              v-slot="{ passed, failed,errors }">
              <fg-input type="number"
                        :error="failed ? errors[0]: null"
                        label="Electors *"
                        name="electors"
                        @keyup="calcPercentage"
                        fou
                        v-model="formData.electors">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="voters"
              rules="required"
              name="The Voters"
              v-slot="{ passed, failed,errors }">
              <fg-input type="number"
                        :error="failed ? errors[0]: null"
                        label="Voters *"
                        name="voters"
                        @keyup="calcPercentage"
                        fou
                        v-model="formData.voters">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="percentage"
              rules=""
              name="The Percentage"
              v-slot="{ passed, failed,errors }">
              <fg-input type="number"
                        :error="failed ? errors[0]: null"
                        label="Percentage"
                        name="percentage"
                        @keyup="calcVoters"
                        min="0"
                        max="100"
                        fou
                        v-model="formData.percentage">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="percentage_2018"
              rules=""
              name="The 2018 Percentage"
              v-slot="{ passed, failed,errors }">
              <fg-input type="number"
                        :error="failed ? errors[0]: null"
                        label="2018 Percentage"
                        name="percentage_2018"
                        min="0"
                        max="100"
                        fou
                        v-model="formData.percentage_2018">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/kadaa/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    FgSelect,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        es_name:"",
        fr_name:"",
        en_name:"",
        electoral_department_id: "",
        voters: 0,
        electors: 0,
        percentage:0,
        percentage_2018: '',
      },
      departmentList: [],
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.electoralDistrictForm
    });

    this.axios.post("electoral-districts/builder").then((response) => {
      this.departmentList = response.data.departments;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit الدائرة الصغرى";
        this.getElectoralDistrict();
      } else {
        this.editMode = false;
        this.formTitle = "Add الدائرة الصغرى";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {

    getElectoralDistrict() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("electoral-districts/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "الدائرة الصغرى Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    calcVoters(){
      this.formData.voters = Math.ceil(this.formData.electors * this.formData.percentage / 100);
    },
    calcPercentage(){
      if(this.formData.electors && this.formData.electors != 0 ){
        this.formData.percentage =Math.round(this.formData.voters / this.formData.electors * 100 * 100) / 100;
      }else{
        this.formData.percentage = 0;
      }

    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("electoral-districts/update/" + this.id, this.formData);
        successMessage = "الدائرة الصغرى Item Updated Successfully";
      } else {
        request = this.axios.post("electoral-districts/create", this.formData);
        successMessage = "الدائرة الصغرى Item Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/kadaa/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      });
    }

  }
}
</script>

<style>
</style>
